import {Injectable} from '@angular/core';
import {HelperService} from './helper.service';
import {Title} from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import {IService} from '@/app/interfaces/service.interface';

@Injectable({
  providedIn: 'root',
})
export class AppService {

  appReady = false;
  titleService: Title;

  loading: any;
  isLoading = false;
  loadingCounter = 0;
  isShowToolbar = false;
  isHideToolbarHard = false;
  offline = false;

  pushData: any = null;

  services: IService[] = [
    {
      name: 'Admin',
      id: 'admin',
      logo: '/assets/images/logo/admin.svg'
    },
    {
      name: 'Sakura',
      id: 'sakura',
      logo: '/assets/images/logo/sakura.svg'
    }
  ];

  currentService$ = new BehaviorSubject<IService>(
    JSON.parse(this.getLocalStorage('service')) ?? this.services[0]
  );

  menuIsCollapsed$ = new BehaviorSubject<boolean>(localStorage.getItem("menuIsCollapsed") === "true")

  private blockBack = false;

  constructor(
    titleService: Title,
  ) {
    this.titleService = titleService;
  }

  getLocalStorage(key: string, defaultValue = null) {
    const v = localStorage.getItem(key);
    return v ? v : defaultValue;
  }

  setLocalStorage(key: string, value: string | null) {

    if (value === null) {
      return localStorage.removeItem(key);
    } else {
      return localStorage.setItem(key, value);
    }

  }


  setOffline() {
    this.offline = true;
  }

  setOnline() {
    this.offline = false;
  }

  refresh() {
    window.location.reload();
  }

  setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  }
}

import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainModelService} from '@/app/core/services/main-model.service';
import {AppService} from '@/app/services/app.service';
import {ApiResponse} from '@/app/entities/api-response/api-response.entity';
import {Pagination} from '@/app/entities/api-response/pagination.entity';

@Injectable({
  providedIn: 'root'
})
export class SakuraModelService extends MainModelService {

  // public requestPrefix = environment.requestPrefix;
  // public requestParam = environment.requestParam;
  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  async get(
    getData: any,
    request: string,
    callback: (answer: ApiResponse) => void,
    loading = false,
    message: boolean | string = false,
    cancelRequest = true
  ) {
    // getData = this.addRequestToGet(getData, request);
    await this.getRequest(getData, callback, request, loading, message, cancelRequest);
  }

  async post(
    postData: any,
    getData: any,
    request: string,
    callback: (answer: ApiResponse) => void,
    loading: boolean = true,
    cancelRequest = true
  ) {
    // getData = this.addRequestToGet(getData, request);
    await this.postRequest(postData, getData, callback, request, loading, cancelRequest);
  }

  async delete(
    postData: any,
    getData: any,
    request: string,
    callback: (answer: ApiResponse) => void,
    loading: boolean = true,
    cancelRequest = true
  ) {
    // getData = this.addRequestToGet(getData, request);
    await this.deleteRequest(postData, getData, callback, request, loading, cancelRequest);
  }

  protected async handleError(data: any, callback: (answer: ApiResponse) => void, message: string) {

    let m = data?.error?.message;
    if (data?.error?.errors?.value_date) {
      m = data.error?.errors?.value_date?.[0];
    }
    if (data?.error?.errors?.value_date_period) {
      m = data.error?.errors?.value_date_period?.[0];
    }
    if (data?.error?.errors?.value_json) {
      m = data.error?.errors?.value_json?.[0];
    }
    if (data?.error?.errors?.value_number) {
      m = data.error?.errors?.value_number?.[0];
    }
    if (data?.error?.errors?.value_string) {
      m = data.error?.errors?.value_string?.[0];
    }
    if (!data.status) {
      m = data.message;
    }

    const response: ApiResponse = new ApiResponse({status: false, message: m});

    // if (message) {
    //   await this.appService.appModalService.showToast(
    //     response.message ? response.message : (typeof message === 'string' ? message : 'Ошибка'),
    //     'danger'
    //   );
    // }

    super.handleError(response, callback);
  }

  protected async handleSuccess(httpResponse: any, callback: (answer: ApiResponse) => void) {
    const data = httpResponse.body;
    const response: ApiResponse = typeof data.status !== 'undefined' ? new ApiResponse(data) : this.packAnswerToApiResponse(data, httpResponse);

    this.addPaginationObject(response, httpResponse);

    super.handleSuccess(response, callback);
  }

  protected addPaginationObject(response: ApiResponse, httpResponse) {
    if (httpResponse?.headers?.get('x-pagination-page-count')) {
      response.pagination = new Pagination(
        httpResponse?.headers?.get('x-pagination-current-page'),
        httpResponse?.headers?.get('x-pagination-per-page'),
        httpResponse?.headers?.get('x-pagination-page-count'),
        httpResponse?.headers?.get('x-pagination-total-count')
      );
    }
  }

  protected packAnswerToApiResponse(data, httpResponse): ApiResponse {
    const response: ApiResponse = new ApiResponse({});

    if (typeof data === 'string') {
      data = data === 'true' || data === '1';
    }

    if (typeof data === 'boolean') {
      response.status = data;
      response.message = 'Пользователь не найден';
    } else {
      if (typeof data.success !== 'undefined') {
        response.status = data.success;
        delete data.success;
      }

      if (typeof data.message !== 'undefined') {
        response.message = data.message;
        delete data.message;
      }

      response.result = data;
    }

    return response;
  }

  // private addRequestToGet(data = {}, request: string) {
  //   return {...{[this.requestParam]: `${this.requestPrefix}${request}`}, ...data};
  // }
}

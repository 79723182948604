import {RouterEntity, RouterList} from '@/app/entities/router/router.entity';
import {plainToInstance} from 'class-transformer';
import {AuthGuard} from '@/app/guards/auth.guard';
import {UnAuthGuard} from '@/app/guards/unAuth.guard';
import {UserAccessModule} from '@/app/pages/user-access/page.module';

const ROUTER_LIST: Omit<RouterEntity, "isAccess" | "toRouter" | "toMenu">[] = [
  {
    icon: '',
    title: 'Blank',
    path: 'blank',
    loadChildren: () => import('./pages/blank/page.module').then(m => m.BlankPageModule)
  },
  {
    icon: '',
    title: 'Authorization',
    path: 'authorization',
    loadChildren: () => import('./pages/auth/page.module').then(m => m.AuthPageModule),
    canActivateChild: [UnAuthGuard],
  },
  {
    icon: '',
    title: 'Onboarding',
    path: 'onboarding',
    isInAppContainer: false,
    loadChildren: () => import('./pages/onboarding/page.module').then(m => m.OnboardingPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: '',
    title: 'Organization',
    path: 'organization',
    loadChildren: () => import('./pages/organization/page.module').then(m => m.OrganizationPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: '',
    title: 'User access',
    path: 'user-access',
    loadChildren: () => import('./pages/user-access/page.module').then(m => m.UserAccessModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: '',
    title: 'Trigger Edit',
    path: 'trigger/edit',
    loadChildren: () => import('./pages/trigger/trigger-edit/page.module').then(m => m.TriggerEditPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: '',
    title: 'Trigger Edit With Id',
    path: 'trigger/edit/:id',
    loadChildren: () => import('./pages/trigger/trigger-edit/page.module').then(m => m.TriggerEditPageModule),
    canActivateChild: [AuthGuard],
  },


  {
    icon: 'menu-triggers',
    title: 'Правила автоматизации',
    path: 'trigger/list',
    isVisibleInMainMenu: true,
    loadChildren: () => import('./pages/trigger/trigger-list/page.module').then(m => m.TriggerListPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: 'menu-accesses',
    title: 'Доступы',
    path: 'user-access',
    isVisibleInMainMenu: true,
    loadChildren: () => import('./pages/blank/page.module').then(m => m.BlankPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: 'menu-settings',
    title: 'Настройки',
    path: 'settings',
    isVisibleInBottomMenu: true,
    loadChildren: () => import('./pages/blank/page.module').then(m => m.BlankPageModule),
    canActivateChild: [AuthGuard],
  },
  {
    icon: 'menu-help',
    title: 'Справка',
    path: 'help',
    isVisibleInBottomMenu: true,
    loadChildren: () => import('./pages/blank/page.module').then(m => m.BlankPageModule),
    canActivateChild: [AuthGuard],
  },

  { path: '**',  redirectTo: 'trigger/list', pathMatch: 'full' }
]

export const APP_ROUTER_LIST = new RouterList();

APP_ROUTER_LIST.push(...plainToInstance(RouterEntity, ROUTER_LIST) as unknown as RouterEntity[])

import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AppService} from '@/app/services/app.service';
import {UserModelService} from '@/app/services/user-model.service';
import {HelperService} from '@/app/services/helper.service';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  appService: AppService;
  userModelService: UserModelService;
  helperService: HelperService;
  router: Router;
  location: Location

  constructor(
    appService: AppService,
    helperService: HelperService,
    userModelService: UserModelService,
    router: Router,
    location: Location
  ) {
    this.appService = appService;
    this.userModelService = userModelService;
    this.helperService = helperService;
    this.router = router;
    this.location = location;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    // if (!this.appService.appReady) {
    //   await this.helperService.wait(() => this.appService.appReady === true);
    // }

    if (!this.userModelService.isLogin()) {
      this.userModelService.currentLocation = state.url;
      await this.router.navigate(['/authorization'], {replaceUrl: true});
      return false;
    }

    if (this.userModelService.isLogin()) {
      if (next?.data?.permission) {
        // this.userModelService.permissions.checkPermission(next.data.permission);
        const isOk = true;

        if (!isOk) {
          this.location.back();
        }

        return isOk;
      } else {
        return true;
      }
    }

    this.location.back();
    return false;
  }

}

import {Inject, Injectable, Injector} from '@angular/core';
import {ParentModalService} from '@/app/services/modals/parent-modal.service';
import {ModalEntity} from '@/app/entities/form-modal/form-modal.entity';
import {UniqueModalData} from '@/app/modals/components/unique/unique-modal.interfaces';
import {BlankModalComponent} from '@/app/modals/components/blank/item';
import {UniqueModalComponent} from '@/app/modals/components/unique/item';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(
    private injector: Injector,
    @Inject(ParentModalService) private readonly modalService: ParentModalService,
  ) { }


  open(data: ModalEntity<any>, callback?: (data: any) => void) {
    this.modalService
      .open(null, data)
      .subscribe(value => {
        if (callback) {
          callback(value);
        }
      });
  }

  openUnique(data: UniqueModalData, callback?: (data: any) => void) {
    this.open({dynamicComponent: UniqueModalComponent, data},(value) => {
      callback(value);
    })
  }
}

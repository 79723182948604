<div class='p-fluid relative'
     [class.floated]="!['number-range', 'toggle'].includes(type)"
     [class.clearable]="clearable && !readonly"
     [class.readonly-input]="readonly"
     appInputFocused
>
  @if (['number-range', 'toggle'].includes(type)) {
    <label>{{ label }}</label>
  } @else {
    <label class="floated-label" [class.floated-active]="inputFocused?.isFocus() || control.value">{{ label }}</label>
  }

  @switch (type) {
    @case ('text') {
      <input pInputText
          autocomplete="off"
          [type]="htmlType"
          [formControl]='control'
          [minLength]="minLength"
          [maxLength]="maxLength"
          [placeholder]="placeholder"
          [readonly]="readonly"
          [style]="inputStyle"
          (input)="onInput.emit($event)"
          (focus)="onFocus.emit($event)"
          (blur)="onBlur.emit($event)"
          (keydown)="onKeyDown.emit($event)"
      />

      @if (clearable && !readonly) {
        <button class="clear-btn" (click)="clearControl()">
          <i class="pi pi-times"></i>
        </button>
      }
    }

    @case ('pattern') {
      <input pInputText
             autocomplete="off"
             regexInput
             [regexInput]="characterPattern"
             [type]="htmlType"
             [formControl]='control'
             [minLength]="minLength"
             [maxLength]="maxLength"
             [placeholder]="placeholder"
             [readonly]="readonly"
             [style]="inputStyle"
             (input)="onInput.emit($event)"
             (focus)="onFocus.emit($event)"
             (blur)="onBlur.emit($event)"
             (keydown)="onKeyDown.emit($event)"
      />

      @if (clearable && !readonly) {
        <button class="clear-btn" (click)="clearControl()">
          <i class="pi pi-times"></i>
        </button>
      }
    }
    @case ('number') {
      <p-inputNumber
          autocomplete="off"
          inputId="integeronly"
          [formControl]='control'
          [useGrouping]="false"
          [min]="minValue"
          [max]="maxValue"
          [readonly]="readonly"
          [inputStyle]="inputStyle"

          (onInput)="onInput.emit($event)"
          (onFocus)="onFocus.emit($event)"
          (onBlur)="onBlur.emit($event)"
          (onKeyDown)="onKeyDown.emit($event)"
      ></p-inputNumber>
    }
    @case ('number-range') {
      <app-range
          [formControl]='control'
          [max]="maxValue"
          [min]="minValue"
          [style]="inputStyle"
      ></app-range>
    }
    @case ('mask') {
      <p-inputMask
          #maskEl
          autocomplete="off"
          [slotChar]="slotChar"
          [type]="htmlType"
          [formControl]='control'
          [mask]="mask"
          [readonly]="readonly"
          [style]="inputStyle"
          (onInput)="onInput.emit($event)"
          (onFocus)="onFocus.emit($event)"
          (onBlur)="onBlur.emit($event)"
          (onKeydown)="onKeyDown.emit($event)"
          (paste)="onPaste($event)"
      ></p-inputMask>
    }
    @case ('textarea') {
      <textarea
          autocomplete="off"
          pInputTextarea
          [formControl]='control'
          [rows]='rows'
          [cols]='cols'
          [readonly]="readonly"
          [style]="inputStyle"
          (input)="onInput.emit($event)"
          (focus)="onFocus.emit($event)"
          (blur)="onBlur.emit($event)"
          (keydown)="onKeyDown.emit($event)"
      ></textarea>
    }
    @case ('toggle') {
      <p-inputSwitch
        [formControl]='control'
        [readonly]="readonly"
        [style]="inputStyle"
      >
      </p-inputSwitch>
    }
    @case ('password') {
<!--     done @todo that why i as Vadim to move such things to separate components, because it will become bigger nd bigger since time. Can not fix it right now, but refactor if possible,-->
      <app-password
        [formControl]="control"
        [feedback]="feedback"
        [inputStyle]="inputStyle"
      ></app-password>
    }
  }
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, HostBinding,
  Inject,
  OnInit,
} from '@angular/core';
import {TuiDialogCloseService} from '@taiga-ui/core';
import {POLYMORPHEUS_CONTEXT} from '@tinkoff/ng-polymorpheus';
import {Observable} from 'rxjs';
import {TuiDestroyService, TuiDialog} from '@taiga-ui/cdk';
import {takeUntil} from 'rxjs/operators';
import {ModalEntity} from '@/app/entities/form-modal/form-modal.entity';

@Component({
  selector: 'app-parent-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDestroyService, TuiDialogCloseService],
})
export class ParentModalComponent implements OnInit {
  cdr: ChangeDetectorRef;
  destroy$: TuiDestroyService;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) readonly context: TuiDialog<ModalEntity<any>, any>,
    @Inject(TuiDialogCloseService) close$: Observable<unknown>,
    destroy$: TuiDestroyService,
    cdr: ChangeDetectorRef
  ) {
    close$
      .pipe(takeUntil(destroy$))
      .subscribe(() => this.context.$implicit.complete());

    this.destroy$ = destroy$;
    this.cdr = cdr;
  }

  @HostBinding('style.max-width.px') get maxWidth() {
    return this.context.data?.maxWidth ?? 450;
  }

  ngOnInit() {}


  get inputContext(): {inputs: Record<string, unknown>} {
    return {inputs: {context: this.context}}
  }
}

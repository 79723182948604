import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ButtonEntity} from '@/app/entities/form-modal/form-modal.entity';
import {TuiAppearance} from '@taiga-ui/core';
import {TUI_ARROW} from '@taiga-ui/kit';
import {LookinSDK, Organization, WorkerAccessToOrganization, WorkerAccessToOrganizationStatus} from 'lookin-sdk';
import {AbstarctCommonPage} from '@/app/core/abstracts/common-page';
import {log} from '@angular-devkit/build-angular/src/builders/ssr-dev-server';
import {Pagination} from 'lookin-sdk/types/entities/Pagination';
import {NotificationCollection} from 'lookin-sdk/types/entities/notification/NotificationCollection';
import {Notification} from 'lookin-sdk/types/entities/notification/Notification';
import {HelperService} from '@/app/services/helper.service';
import {IService} from '@/app/interfaces/service.interface';
import {plainToInstance} from 'class-transformer';
import {Form} from '@/app/entities/form/form.entity';

@Component({
  selector: 'app-page-header',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class PageHeaderComponent extends AbstarctCommonPage implements OnInit, AfterViewInit {
  readonly arrow = TUI_ARROW;

  @ViewChild('userMenuButton') userMenuButton: ElementRef;
  @ViewChild('userMenuTop') userMenuTop: ElementRef;
  @ViewChild('userMenuBottom') userMenuBottom: ElementRef;

  @ViewChild('serviceMenuButton') serviceMenuButton: ElementRef;
  @ViewChild('serviceMenuTop') serviceMenuTop: ElementRef;
  @ViewChild('serviceMenuBottom') serviceMenuBottom: ElementRef;

  @ViewChild('tabView') tabView: ElementRef;

  @Input() title: string;
  @Input() addButton: ButtonEntity = {
    icon: 'tuiIconPlus',
    appearance: TuiAppearance.Primary,
    size: 's',
    text: 'Добавить'
  };
  @Input() dropdownButton: Omit<ButtonEntity, 'icon'> = {
    appearance: TuiAppearance.Primary,
    size: 's',
    text: 'Выбрать'
  };
  @Input() dropdownButtonContent: TemplateRef<any>;

  @Output() addButtonClick = new EventEmitter<void>();

  isOpen = false;
  isOpenNotification = false;
  isOpenService = false;

  loading = false;
  notificationLoading = false;
  invitation: WorkerAccessToOrganization[];
  activeOrganization: WorkerAccessToOrganization[];

  pagination: Pagination = <Pagination>{
    currentPage: 1,
    perPage: 1000
  };
  notifications: [NotificationCollection, Notification[], Notification[]];

  editFormFields = [
    {
      "slug": "title",
      "elementConfiguration": {
        "type": "text",
        "title": "Название*",
        "configuration": {
          "type": "text"
        },
        "validation": [
          {
            "type": "required",
            "text": "Обязательное поле"
          }
        ]
      }
    },
    {
      "slug": "description",
      "elementConfiguration": {
        "type": "text",
        "title": "Описание",
        "configuration": {
          "type": "text"
        },
      }
    },
    {
      "slug": "domain",
      "elementConfiguration": {
        "type": "text",
        "title": "Домен",
        "configuration": {
          "type": "text",
          "readonly": true
        },
      }
    },
  ];

  ngOnInit() {
    Promise.all([
      LookinSDK.workerAccessToOrganizationModel.getItems(this.userModel.user.id.toString(), WorkerAccessToOrganizationStatus.active),
      LookinSDK.workerAccessToOrganizationModel.getItems(this.userModel.user.id.toString(), WorkerAccessToOrganizationStatus.underConsideration),
      LookinSDK.notificationModel.q().fields(...LookinSDK.notificationModel.baseFields()).get(this.pagination.currentPage, this.pagination.perPage)
    ]).then(([active, invitation, notification]) => {
      if (invitation.status) {
        this.invitation = invitation.data;
      }

      if (active.status) {
        this.activeOrganization = active.data;
      }

      this.loading = false;

      this.setNotifications(notification)
      
      this.cdr.markForCheck();
      console.log({invitation, active, notification});
    })
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();

    setInterval(() => { this.updateNotifications() }, 60000);
  }

  updateNotifications() {
    LookinSDK.notificationModel
      .q()
      .fields(...LookinSDK.notificationModel.baseFields())
      .get(this.pagination.currentPage, this.pagination.perPage)
      .then(notification => {
        this.setNotifications(notification)
      })
  }

  setNotifications(notification: NotificationCollection) {
    this.notifications = [
      notification,
      notification.filter(item => !item.isRead),
      notification.filter(item => item.isRead),
    ];
  }

  selectOrganization(item: WorkerAccessToOrganization) {
    this.userModel.user.changeOrganization(item.organization.id).then(res => {
      if (res) {
        window.location.reload();
      }
    })
  }

  notificationUpdate(item: Notification) {
    const fIdx = this.notifications[1].findIndex(n => n.id === item.id);
    if (fIdx > -1) {
      this.notifications[1].splice(fIdx, 1);
    }
    item.markRead().then(res => {
      this.notifications[2].push(item);
    })
  }

  readAll() {
    this.notificationLoading = true;
    this.notifications[0].markReadAll().then(() => {
      LookinSDK.notificationModel
        .q()
        .fields(...LookinSDK.notificationModel.baseFields()).get(this.pagination.currentPage, this.pagination.perPage)
        .then(notification => {
          this.notifications = [
            notification,
            notification.filter(item => !item.isRead),
            notification.filter(item => item.isRead),
          ];
          this.notificationLoading = false;
          this.cdr.markForCheck();
        })
    })
  }

  rejectInvitation(item: WorkerAccessToOrganization) {
    LookinSDK.workerAccessToOrganizationModel.update(item.id, WorkerAccessToOrganizationStatus.rejected).then(res => {
      window.location.reload();
    })
  }

  acceptInvitation(item: WorkerAccessToOrganization) {
    LookinSDK.workerAccessToOrganizationModel.update(item.id, WorkerAccessToOrganizationStatus.active).then(res => {
      window.location.reload();
    })
  }

  onObscured(obscured: boolean): void {
    if (obscured) {
      this.isOpen = false;
      this.isOpenNotification = false;
      this.isOpenService = false;
    }
  }

  onActiveZone(active: boolean): void {
    this.isOpen = active && this.isOpen;
    this.isOpenNotification = active && this.isOpenNotification;
    this.isOpenService = active && this.isOpenService;
  }

  get maxUserMenuHeight(): number {
    const element = this.userMenuButton?.nativeElement as HTMLElement;
    if (element) {
      const rect = element.getBoundingClientRect();

      return window.innerHeight - rect.bottom - 70;
    }
    return 400;
  }

  get maxAccordionHeight(): number {
    const topEl = this.userMenuTop?.nativeElement as HTMLElement;
    const bottomEl = this.userMenuBottom?.nativeElement as HTMLElement;

    if (topEl && bottomEl) {
      return (this.maxUserMenuHeight - topEl.offsetHeight - bottomEl.offsetHeight) / 2;
    }

    return 150;
  }

  get maxNotificationHeight(): number {
    const nEl = this.tabView?.nativeElement as HTMLElement;

    if (nEl) {
      const navEl = nEl.querySelector('.p-tabview-nav');

      return 440 - navEl.getBoundingClientRect().height;
    }

    return 300;
  }

  get maxServiceMenuHeight(): number {
    const element = this.serviceMenuButton?.nativeElement as HTMLElement;
    if (element) {
      const rect = element.getBoundingClientRect();

      return window.innerHeight - rect.bottom - 70;
    }
    return 400;
  }

  get maxServiceAccordionHeight(): number {
    const topEl = this.serviceMenuTop?.nativeElement as HTMLElement;
    const bottomEl = this.serviceMenuBottom?.nativeElement as HTMLElement;

    if (topEl && bottomEl) {
      return (this.maxServiceMenuHeight - topEl.offsetHeight - bottomEl.offsetHeight);
    }

    return 150;
  }

  get activeOrganizations(): WorkerAccessToOrganization[] {
    return this.activeOrganization && this.userModel.user?.selectedOrganization ? this.activeOrganization.filter(item => item.organizationId.toString() !== this.userModel.user.selectedOrganization?.id) : []
  }

  openCurrentWorker() {
    HelperService.openWorkerInSakura(this.userModel.user.currentWorker().id.toString());
  }

  changeService(service: IService, disabled: boolean) {
    if (disabled) {
      return;
    }
    this.appService.setLocalStorage('service', JSON.stringify(service))
    this.appService.currentService$.next(service);
  }

  openEditOrg() {
    const org = this.userModel.user.selectedOrganization;
    const editForm = plainToInstance(Form, {
      fields: this.editFormFields,
      value: {title: org.title, description: org.description, domain: org.domain}
    });

    this.modalService.openUnique({
      title: 'Редактировать организацию',
      buttons: [
        {
          settings: {
            label: 'Отменить',
            severity: 'primary',
            size: 'small',
            text: true
          },
          isClose: true
        },
        {
          settings: {
            label: 'Сохранить',
            severity: 'primary',
            size: 'small'
          },
          isClose: false,
          callbackOnClick: (modal) => {
            modal.makeAllFormTouched = true;
            setTimeout(() => {
              if (modal.formEl.formGroup.invalid) {
                return;
              }

              const value = HelperService.convertNullToEmptyString(modal.control.value);
              LookinSDK.organizationModel.update(this.userModel.user.selectedOrganization.id, value.title, value.description, value.domain).then(res => {
                modal.context.$implicit.complete();
                if (res.status) {
                  this.userModel.makeLogin();
                  this.toast.add({summary: `Организация ${value.title} отредактирована`, severity: 'success'});
                } else  {
                  this.toast.add({summary: res.message, severity: 'error'});
                  if (modal.formEl?.formGroup && res.formErrors) {
                    modal.formEl.setServerErrors(res.formErrors);
                  }
                }
              })
            });
          }
        },
      ],
      form: {...editForm}
    }, (value) => {
      console.log({value});
    });
  }
}

import {Injectable, Injector, inject} from '@angular/core';
import {SakuraModelService} from '@/app/core/services/sakura-model.service';
import {HttpClient} from '@angular/common/http';
import {AppService} from '@/app/services/app.service';
import {ApiResponse} from '@/app/entities/api-response/api-response.entity';
import { environment } from '@/environments/environment';
import {Router} from '@angular/router';
import {LookinSDK, User} from 'lookin-sdk';

@Injectable({
  providedIn: 'root'
})
export class UserModelService extends SakuraModelService {
  currentLocation: string;
  startUrl: string;
  router = inject(Router);
  user: User;

  constructor(injector: Injector) {
    super(injector);
  }
  get token(): string {
    if (typeof window['authToken']!=='undefined') {
      return window['authToken'];
    }

    return environment.service === 'admin' ? localStorage.getItem('token') ?? '' : 'GPs0w0pmPrYXe_l8K4X2ZV6WGF8oLAZ8';
  }

  set token(token: string) {
    localStorage.setItem('token', token);
  }

  makeLogin(callback: (status: boolean) => void = () => {}) {
    if (this.token) {
      if (environment.service === 'admin') {
        LookinSDK.authService?.start().then(res => {
          if (res && res instanceof User) {
            this.user = res;
            console.log({user: res})

          if (!this.user.selectedOrganization) {
            this.router.navigate(['/onboarding'], {replaceUrl: true});
          } else {
            // this.router.navigate(['/organization'], {replaceUrl: true});
            if (this.startUrl) {
              this.router.navigate([this.startUrl], {replaceUrl: true});
            } else {
              this.router.navigate(['/organization'], {replaceUrl: true});
            }

            this.startUrl = null;
          }

            callback(true);
          } else {
            this.token = '';
            this.goToAuth();
            callback(false);
          }
        })
      } else {
        callback(true);
      }
    } else {
      this.goToAuth();
      callback(false);
    }
  }

  goToAuth() {
    const isAuth = window.location.pathname.split('/')?.includes('authorization');
    if (isAuth && !this.token) {
      return;
    }
    if (environment.service === 'admin') {
      this.router.navigate(['/authorization/'], {replaceUrl: true});
    } else {
      window.location.href = '/user-management/auth/login';
    }
  }

  login(emailOrPhone: string, password: string, rememberMe: boolean = false, callback: (isSuccess: boolean) => void) {
    this.post({
      operationName: 'Login',
      query: `mutation Login{login(email:"${emailOrPhone}", password:"${password}")}`
    }, {}, 'graphql', (res) => {
      if (res.status) {
        this.token = res.result.data.login.access_token;
      }
      callback(res.status);
    })
  }

  logout() {
    this.user = null;
    localStorage.removeItem('token');
    this.router.navigate(['/authorization'], {replaceUrl: true});
  }

  isLogin(): boolean {
    return !!this.token;
  }

  getStartData(callback: (answer: ApiResponse) => void) {
    this.post({
      operationName: 'StartData',
      query: 'query StartData { me { id name surname selectedOrganization { id title workers { id } } selectedWorker { id } } organizations(first: 10, where: {HAS: {relation: "workers"}}) { paginatorInfo { total } data { id title workers { id name userId } } } }'
    }, {}, 'graphql', (answer: ApiResponse) => {
      callback(answer);
    });
  }
}

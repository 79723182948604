<tui-accordion
    class="w-full accordion"
    [rounded]="false"
    [closeOthers]="false"
>
    <tui-accordion-item
        [borders]="null"
        [showArrow]="false"
        [noPadding]="true"
        [disableHover]="true"
        [open]="isOpen"
        (openChange)="toggle($event)"
    >
      <div #accordionHeader [style.padding-right.px]="indentToRight">
        <div class="accordion-header flex justify-content-between" [class.no-border]="hideBorder">
          <div class="flex-1">
            <ng-content select="[accordionHeader]"></ng-content>
          </div>

          <tui-svg [src]="isOpen ? 'tuiIconChevronUp' : 'tuiIconChevronDown'"></tui-svg>
        </div>
      </div>
      <ng-template tuiAccordionItemContent>
        <div class="accordion-body">
          <tui-scrollbar
              [style.max-height.px]="scrollMaxHeight"
              (scroll)="onScroll()"
          >
            <div [style.padding-right.px]="indentToRight">
              <ng-content select="[accordionBody]"></ng-content>
            </div>
          </tui-scrollbar>
        </div>
      </ng-template>
    </tui-accordion-item>
</tui-accordion>

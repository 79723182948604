<div class="menu" [ngClass]="{'menu--collapsed': isCollapsed}">
  <div class="menu__organization" [routerLink]="'organization'" routerLinkActive="active-link">
    <app-organization-card
      [showDescription]="true"
      [showAccept]="false"
      [lineLimitHeader]="1"
      [lineLimitDescription]="1"
      [organization]="userModel.user.selectedOrganization"
      [isDark]="true"
      [isOnMenu]="true"
    ></app-organization-card>
  </div>

  <div class="menu__main">
    @for (route of mainMenuRoutes; track route.name) {
      <app-menu-item
          [label]="route.title"
          [routerLink]="route.path"
          routerLinkActive
          #rla="routerLinkActive"
          [isActive]="rla.isActive"
          [isHide]="isCollapsed"
      >
        <app-icon
            menuItemIcon
            [iconName]="route.icon"
            [outputSvg]="true"
            [customFillColor]="rla.isActive ? '#E0E4EE' : ''"
        ></app-icon>
      </app-menu-item>
    }
  </div>

  <div class="menu__bottom">
    @for (route of bottomMenuRoutes; track route.name) {
      <app-menu-item
          [label]="route.title"
          [routerLink]="route.path"
          routerLinkActive
          #rla="routerLinkActive"
          [isActive]="rla.isActive"
          [disabled]="true"
          [isHide]="isCollapsed"
      >
        <app-icon
            menuItemIcon
            [iconName]="route.icon"
            [outputSvg]="true"
            [customFillColor]="rla.isActive ? '#E0E4EE' : ''"
        ></app-icon>
      </app-menu-item>
    }
  </div>

  <app-button
    class="menu__collapse"
    styleClass="px-12 border-none"
    [outlined]="true"
    (click)="toggleCollapseMenu()"
  >
    <app-icon class="menu__collapse-icon" iconName="menu-collapse"></app-icon>
  </app-button>
</div>

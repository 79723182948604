<div class="modal">
  <div class="tui-text_h6">{{context.data.title}}</div>
  <tui-scrollbar class="modal-content">
    <div class="modal-content_box">
      @if (context.data.description) {
        <p class="modal-description">{{context.data.description}}</p>
      }

      @if (context.data.form) {
        <app-form-element
          #formEl
          [formControl]="control"
          [makeAllFormTouched]="makeAllFormTouched"
          (additionalFormIsInvalid)="formIsInvalid = $event"
        ></app-form-element>
      }
    </div>
  </tui-scrollbar>
  <div class="modal-footer flex justify-content-end align-items-center gap-2 flex-wrap">
    @for (button of context.data.buttons; track button.settings.label) {
      <app-button
          [icon]="button.settings.icon"
          [label]="button.settings.label"
          [severity]="button.settings.severity"
          [size]="button.settings.size"
          [raised]="button.settings.raised"
          [outlined]="button.settings.outlined"
          [text]="button.settings.text"
          [loading]="loading"
          (onClick)="onClick(button)"
      ></app-button>
    }
  </div>
</div>

<div
    class="worker flex"
    appRipple
    [disable]="!(isDepartmentClick || isPositionClick || isWorkerClick)"
    [class.worker-hover]="!isWorkerClick"
>
  <div class="worker-img">
    <app-avatar
        [size]="size"
        [name]="worker.name"
        [rounded]="true"
        [hovered]="isWorkerClick"
        (click)="onWorkerClick.emit(worker)"
    ></app-avatar>
  </div>
  <div class="worker-block flex-1">
    <div
        class="worker-name"
        [ngStyle]="{'-webkit-line-clamp': lineLimitName}"
        [class.worker-name-hover]="isWorkerClick"
        (click)="onWorkerClick.emit(worker)"
    >{{ worker.name }}</div>
    <div class="worker-wrap">
      @if (worker.postName) {
        <div
            class="worker-item flex align-items-center"
            [class.worker-item-hover]="isPositionClick"
            (click)="onPositionClick.emit(worker)"
        >
          <div class="worker-item-icon">
            <i class="pi pi-user text-lg"></i>
          </div>
          <div class="worker-item-text" [ngStyle]="{'-webkit-line-clamp': lineLimitItem}">
            {{ worker.postName }}
          </div>
        </div>
      }

      @if (worker.filialName) {
        <div
            class="worker-item flex align-items-center"
            [class.worker-item-hover]="isDepartmentClick"
            (click)="onDepartmentClick.emit(worker)"
        >
          <div class="worker-item-icon">
            <i class="pi pi-map-marker text-lg"></i>
          </div>
          <div class="worker-item-text" [ngStyle]="{'-webkit-line-clamp': lineLimitItem}">
            {{ worker.filialName }}
          </div>
        </div>
      }
    </div>
  </div>
</div>

export const environment = {
  title: 'Sakura Admin',
  service: 'admin',
  production: false,
  baseUrl: 'https://rc.api.admin.lookin.lobsterlab.io',
  sakuraUrl: 'https://rc.hrm.lookin.lobsterlab.io',
  gqlUrl: 'https://rc.gateway.lookinsoft.ru',
  publicUrl: '',
  downloadUrl: 'api/pwa/download?url=',
  apiUrl: '',
};

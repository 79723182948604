import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, HostBinding, inject, Input,
  OnInit, ViewChild,
} from '@angular/core';
import {TuiDialog} from '@taiga-ui/cdk';
import {ModalEntity} from '@/app/entities/form-modal/form-modal.entity';
import {TuiScrollbarModule} from '@taiga-ui/core';
import {ButtonModule} from '@/app/components/app-components/button/item.module';
import {UniqueModalData} from '@/app/modals/components/unique/unique-modal.interfaces';
import {FormModule} from '@/app/components/form-components/form-constructor/form.module';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {FormElementComponent} from '@/app/components/form-components/form-constructor/form-element/item';
import {ModalButton} from '@/app/entities/modal-button/modal-button.interface';
import {FormElementServerError} from '@/app/entities/form/form-element.entity';

@Component({
  selector: 'app-unique-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiScrollbarModule,
    ButtonModule,
    FormModule,
    ReactiveFormsModule
  ]
})
export class UniqueModalComponent implements OnInit, AfterViewInit {
  @Input() context: TuiDialog<ModalEntity<UniqueModalData>, any>;
  @HostBinding('class.modal-parent') setParent() {
    return true;
  }

  @ViewChild('formEl') formEl: FormElementComponent;

  cdr = inject(ChangeDetectorRef);
  control = new FormControl();
  makeAllFormTouched = false;
  formIsInvalid = false;
  loading = false;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.context.data.form?.fields) {
      this.formEl.build(this.context.data.form.fields, this.context.data.form.value)
    }
  }

  onClick(button: ModalButton<UniqueModalComponent>) {
    if (button.callbackOnClick) {
      button.callbackOnClick(this);
    }

    if (button.isClose) {
      this.context.$implicit.complete();

      if (button.callbackAfterClose) {
        button.callbackAfterClose(this);
      }
    }
  }

  setErrors(error: {[key: string]: FormElementServerError[]}) {
    this.formEl.setServerErrors(error);
    this.cdr.markForCheck();
  }
}
